export const resourcesUdia = {
    "resources": [
        {
            "id": "03d1abac-994a-4b2c-9d1c-66181a36b345",
            "name": "conteiner",
            "totalCost": 11.9932903224,
            "volumes": [
                {
                    "assetId": "3be96cf0-935a-4f34-becd-c5f71c6189d8",
                    "diskType": "SSD Standard",
                    "diskSize": 100,
                    "diskPrice": 2.352
                }
            ],
            "flavor": {
                "flavorUid": "523ea88e-475e-454f-9268-3de5631a8c7a",
                "flavorName": "Micro",
                "systemName": "Ubuntu 21",
                "flavorPrice": 9.1612903224,
                "vcpu": "2",
                "ram": 8
            },
            "ips": [
                {
                    "floatingIps": "200.225.247.34",
                    "priceIP": 0.48
                }
            ]
        },
        {
            "id": "047d737c-673f-4da2-a582-5a3a2d45368c",
            "name": "pocone.app",
            "totalCost": 21.682193548799997,
            "volumes": [
                {
                    "assetId": "d944a7d6-9727-4d30-b929-365e12c9be89",
                    "diskType": "SSD Standard",
                    "diskSize": 150,
                    "diskPrice": 3.528
                }
            ],
            "flavor": {
                "flavorUid": "7d7367c1-9b93-45df-aa39-17222e7b5b04",
                "flavorName": "MMicro",
                "systemName": "Windows Server 2022",
                "flavorPrice": 17.674193548799998,
                "vcpu": "2",
                "ram": 16
            },
            "ips": [
                {
                    "floatingIps": "200.170.135.132",
                    "priceIP": 0.48
                }
            ]
        },
        {
            "id": "0d42ef69-9b99-4b88-ba0e-e49dde7a91d2",
            "name": "portal",
            "totalCost": 40.532387097599994,
            "volumes": [
                {
                    "assetId": "6edf4504-e321-4681-8fa2-4e984a2927d1",
                    "diskType": "SSD Standard",
                    "diskSize": 100,
                    "diskPrice": 2.352
                },
                {
                    "assetId": "e1d58fcc-41c3-4313-93ce-bd2b3849b6da",
                    "diskType": "SSD Premium",
                    "diskSize": 100,
                    "diskPrice": 2.352
                }
            ],
            "flavor": {
                "flavorUid": "124e2582-013f-4c84-9ee7-d694230a5c7d",
                "flavorName": "Medium",
                "systemName": "Windows Server 2019",
                "flavorPrice": 35.348387097599996,
                "vcpu": "4",
                "ram": 32
            },
            "ips": [
                {
                    "floatingIps": "201.16.213.101",
                    "priceIP": 0.48
                }
            ]
        },
        {
            "id": "17f7ec58-97e9-4364-bd8f-01f50b623a20",
            "name": "mongodb",
            "totalCost": 15.9657548376,
            "volumes": [
                {
                    "assetId": "173aeb2a-ef85-4414-99ef-4c96fd20cf12",
                    "diskType": "SSD Standard",
                    "diskSize": 70,
                    "diskPrice": 1.6463999999999999
                }
            ],
            "flavor": {
                "flavorUid": "523ea88e-475e-454f-9268-3de5631a8c7a",
                "flavorName": "Micro",
                "systemName": "Windows Server 2019",
                "flavorPrice": 14.3193548376,
                "vcpu": "2",
                "ram": 8
            },
            "ips": []
        },
        {
            "id": "19c255d6-51c4-4c24-b282-97611275154c",
            "name": "sqlserverB",
            "totalCost": 396.1009032264,
            "volumes": [
                {
                    "assetId": "5c0aef69-dd37-487f-81aa-d8496b426a08",
                    "diskType": "SSD Standard",
                    "diskSize": 100,
                    "diskPrice": 2.352
                },
                {
                    "assetId": "9daddb28-2e8d-4e54-88fe-a41e315ef2c8",
                    "diskType": "SSD Standard",
                    "diskSize": 100,
                    "diskPrice": 2.352
                },
                {
                    "assetId": "56d979c9-6b58-4d2b-b754-832ea2d1be75",
                    "diskType": "SSD Premium",
                    "diskSize": 1500,
                    "diskPrice": 35.28
                },
                {
                    "assetId": "7e876b60-df98-4950-9375-55fc84b1631e",
                    "diskType": "SSD Premium",
                    "diskSize": 5500,
                    "diskPrice": 129.35999999999999
                },
                {
                    "assetId": "dd7272ad-7e11-42e8-be34-612b85bc206d",
                    "diskType": "SSD Premium",
                    "diskSize": 4500,
                    "diskPrice": 105.84
                }
            ],
            "flavor": {
                "flavorUid": "124e2582-013f-4c84-9ee7-d694230a5c7d",
                "flavorName": "Medium",
                "systemName": "Windows Server 2016 - SQL 2019 Standard",
                "flavorPrice": 120.91690322640001,
                "vcpu": "4",
                "ram": 32
            },
            "ips": []
        }
    ],
    "page": 0,
    "pageSize": 5,
    "totalPages": 1,
    "totalLines": 5
}