import React from "react";
import "./styles.css"

export default function SandBox(){

  // const TemplateCardInfo = (props) => {
  //   return (
  //     <div className="template-card">
  //         <div className="template-card-info">
  //           <img src={SumMachineIcon} alt="logo" />
  //           <span>{props.total}</span>
  //         </div>
  //         <span>Total de instâncias</span>
  //     </div>
  //   );
  // };

  // const TemplateLocalization = (props) => {
  //   return (
  //     <div className="mr-auto">
  //         <div className="template-localization">
  //           <img src={GlobeIcon} alt="logo" />
  //           <span>{props.region}</span>
  //         </div>
  //     </div>
  //   );
  // };
    return (
    <>
    <div className="container">
        {/* <CardInformation
          value={<TemplateCardInfo total={10} />}
          footer={<TemplateLocalization region="São Paulo" />}
        /> */}
    </div>
    </>
   )
}