import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button } from "primereact/button";
import CardInformation from "../../Components/CardInformation";
import { Skeleton } from "primereact/skeleton";
import DialogInstace from "../../Components/DialogInstance";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataScroller } from "primereact/datascroller";
import instanciaService from "../../services/InstanciaService";
import { ProgressSpinner } from "primereact/progressspinner";
import GlobeIcon from "../../assets/globe.png";
import SumMachineIcon from "../../assets/sum-machine.png";
import CreditIcon from "../../assets/credit-card.png";
import { Dropdown } from "primereact/dropdown";

import { SplitButton } from "primereact/splitbutton";
import { InputText } from "primereact/inputtext";
import Export from "../../utils/Export";
import Util from "../../utils/Util";
import { Regions } from "../../utils/Regions";
import { resourcesUdia } from "./mock";
import "./style.css";

const cities = [
  { name: "São Paulo", code: Regions.SAO_PAULO },
  { name: "Uberlândia", code: Regions.NOVA_UBERLANDIA },
];

function Instancias() {
  const [instances, setInstances] = useState({});
  const [instancesNew, setInstancesNew] = useState([]);
  const [filterInstances, setFilterInstances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [selectedCity, setSelectedCity] = useState(cities[0]);

  const instancesSP = useRef(null);
  const instancesUDI = useRef(null);

  const activeRegion = useRef(null);

  const ds = useRef(null);
  const page = useRef(0);

  const totalInstances = useMemo(
    () =>
      instancesNew ? (
        instancesNew.length
      ) : (
        <i className="pi pi-exclamation-triangle"></i>
      ),
    [instancesNew],
  );

  const totalCreditos = useMemo(() => {
    if (instancesNew !== undefined && instancesNew.length > 0) {
      return instancesNew.reduce((acc, val) => acc + val.totalCost, 0);
    }
    return 0;
  }, [instancesNew]);

  const existOnlyOneRegion = () => {
    const sizeResourcesSP = instancesSP?.data?.length ?? 0;
    const sizeResourcesUDI = instancesUDI?.data?.length ?? 0;

    return (
      (instancesSP.current === null && instancesUDI.current != null) ||
      (instancesSP.current != null && instancesUDI.current == null)
    );
  };

  const getRegionExisting = () => {
    const clientId = localStorage.getItem("client_id");

    const localInstancesSP = JSON.parse(
      localStorage.getItem("instances-sp-" + clientId),
    );

    const localInstancesUdi = JSON.parse(
      localStorage.getItem("instances-udi-" + clientId),
    );

    if (localInstancesUdi != null) instancesUDI.current = localInstancesUdi;

    if (localInstancesSP != null) instancesSP.current = localInstancesSP;

    if (selectedCity.code === Regions.SAO_PAULO) {
      activeRegion.current = Regions.SAO_PAULO;

      return localInstancesSP;
    }

    activeRegion.current = Regions.NOVA_UBERLANDIA;
    return localInstancesUdi;
  };

  async function fetchInstances() {
    setLoading(true);
    try {
      const localInstances = getRegionExisting();

      const existInstancesCacheNotExpired =
        localInstances != null &&
        new Date() < new Date(localInstances?.expirationDate);

      if (existInstancesCacheNotExpired) {
        setInstancesNew(localInstances.data);
      } else {
        let response = null;
        let promisseSP = null;
        let promisseUdi = null;

        if (instancesSP.current === null && instancesUDI.current === null) {
          promisseSP = requestFetchInstances(page.current, Regions.SAO_PAULO);

          promisseUdi = requestFetchInstances(
            page.current,
            Regions.NOVA_UBERLANDIA,
          );

          var [responseSP, responseUdi] = await Promise.all([
            promisseSP,
            promisseUdi,
          ]);

          instancesUDI.current = responseUdi;
          instancesSP.current = responseSP;
        }

        if (selectedCity.code === Regions.SAO_PAULO) {
          response = instancesSP.current;
        } else {
          response = instancesUDI.current;
          setSelectedCity(cities[1]);
        }

        if (response != null) {
          setInstancesNew(response.resources);
          setInstances(response);
        }
      }
    } catch (e) {
      console.error("ERROR: cloudplus/openstack-extractor/instances", e);
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchInstances();
  }, [selectedCity]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let aux = instancesNew;

    while (page.current < instances.totalPages) {
      try {
        setLoadingMore(true);
        await Promise.all(
          [...Array(10).keys()].map(async () => {
            return await requestFetchInstances(
              ++page.current,
              selectedCity.code,
            );
          }),
          // eslint-disable-next-line no-loop-func
        ).then((values) => {
          values.map((v) => {
            aux = [...aux, ...v.resources];
          });

          setInstancesNew(aux);
        });
      } catch (e) {
        console.error("error", e);
      }
    }
    setLoadingMore(false);

    if (instances.totalLines === aux.length) {
      const clientId = localStorage.getItem("client_id");

      const localInstances = JSON.parse(
        localStorage.getItem(
          (selectedCity === Regions.SAO_PAULO
            ? "instances-sp-"
            : "instances-udi-") + clientId,
        ),
      );

      if (
        localInstances === null ||
        new Date() > new Date(localInstances?.expirationDate)
      ) {
        let expireDate = new Date();

        expireDate.setHours(expireDate.getHours() + 1);

        let dataToCache = {
          data: aux,
          expirationDate: expireDate,
          region: selectedCity,
        };

        localStorage.setItem(
          (selectedCity.code === Regions.SAO_PAULO
            ? "instances-sp-"
            : "instances-udi-") + clientId,
          JSON.stringify(dataToCache),
        );
      }
    }

    page.current = 0;
  }, [instances]);

  async function requestFetchInstances(page, regionId) {
    const fetch = await instanciaService.fetchDetailedGroupedInstances(
      window.localStorage.getItem("client_id"),
      page,
      regionId,
    );

    return fetch;
  }

  const itemTemplate = (val, index) => {
    return (
      <>
        <Accordion key={index}>
          <AccordionTab header={val.name}>
            <DialogInstace header="Instância" data={val} />
          </AccordionTab>
        </Accordion>
      </>
    );
  };

  const itemsExports = [
    {
      label: "PDF",
      icon: "pi pi-file-pdf",
      command: (e) => {
        exportReportInstances("pdf");
      },
    },
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: (e) => {
        exportReportInstances("xlsx");
      },
    },
  ];

  const loadingButton = (
    <ProgressSpinner
      strokeWidth={2}
      style={{ width: "50px", height: "50px" }}
    />
  );

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const stringBlob = event.target.result;
        resolve(stringBlob);
      };

      reader.onerror = function (event) {
        reject(new Error("Erro na leitura do Blob"));
      };

      reader.readAsDataURL(blob);
    });
  }

  async function exportReportInstances(type) {
    let exportStorage;
    let relatorio;
    var blobToString;

    setLoadingExport(true);

    if (typeof window != "undefined") {
      const clientId = localStorage.getItem("client_id");
      exportStorage = sessionStorage.getItem(clientId + `-${type}`);

      if (
        exportStorage != null &&
        new Date(new Date().toISOString().split("T")[0]) >
          new Date(JSON.parse(exportStorage).expirationDate)
      ) {
        sessionStorage.removeItem(clientId);
        exportStorage = null;
      }

      if (exportStorage === null) {
        if (type === "pdf") {
          relatorio = await instanciaService.exportDetailedGroupedInstances(
            window.localStorage.getItem("client_id"),
            selectedCity.code,
          );
        } else if (type === "xlsx") {
          relatorio =
            await instanciaService.exportDetailedGroupedInstancesExcel(
              window.localStorage.getItem("client_id"),
              selectedCity.code,
            );

          await blobToBase64(relatorio)
            .then((stringBlob) => {
              blobToString = stringBlob;
            })
            .catch((error) => {
              console.log("Error", error);
            });
        }

        let dataToCache = {
          data: blobToString != undefined ? blobToString : relatorio,
          expirationDate: new Date().toISOString().split("T")[0],
        };

        sessionStorage.setItem(
          clientId + `-${type}`,
          JSON.stringify(dataToCache),
        );
      }

      type === "pdf"
        ? Export.openNewTab(
            exportStorage === null ? relatorio : JSON.parse(exportStorage).data,
          )
        : exportStorage === null
          ? Export.download(relatorio, "InstânciasDetalhadas.xlsx")
          : Export.downloadBlobFromUrl(
              JSON.parse(exportStorage).data,
              "InstânciasDetalhadas.xlsx",
            );
    }

    setLoadingExport(false);
  }

  const TemplateCardInfo = (props) => {
    return (
      <div className="template-card">
        <div className="template-card-info">
          <img src={props.icon} alt="Icon" />
          {loading || loadingMore ? (
            <Skeleton width="2rem" />
          ) : (
            <>
              <span>{props.total}</span>
            </>
          )}
        </div>
        <label>{props.title}</label>
      </div>
    );
  };

  const TemplateLocalization = (props) => {
    return (
      <div className="mr-auto">
        <div className="template_localization">
          <img src={GlobeIcon} alt="logo" />
          <span>{props.region}</span>
        </div>
      </div>
    );
  };

  const onChangeSearch = (e) => {
    const search = e.target.value;

    const filter = instancesNew.filter(
      (f) => f.name.includes(search) || f.flavor.flavorName.includes(search),
    );

    setFilterInstances(filter);
  };

  return (
    <div className="margin-itens container-component">
      <h1 className="mb-2">Instâncias</h1>
      <div className="grid-instances grid grid-cols-2 gap-3  mb-5 ml-2 container-grid">
        <CardInformation
          value={
            <TemplateCardInfo
              total={totalInstances}
              title="Total de instâncias"
              icon={SumMachineIcon}
            />
          }
          footer={
            <TemplateLocalization
              region={selectedCity?.name ?? cities[0].name}
            />
          }
        />

        <CardInformation
          value={
            <TemplateCardInfo
              total={Util.formatNumber(totalCreditos)}
              title="Total de créditos"
              icon={CreditIcon}
            />
          }
          footer={
            <TemplateLocalization
              region={selectedCity?.name ?? cities[0].name}
            />
          }
        />
        <div className="export-field">
          {loadingExport ? (
            <ProgressSpinner
              className="p-progress-spinner-color"
              style={{ width: "22px", height: "22px", marginTop: "5px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          ) : (
            ""
          )}
          <SplitButton
            label="Exportar"
            className="p-button-outlined-primary ml-2 p-button-outlined  "
            model={itemsExports}
          />
        </div>
      </div>

      <div>
        <div className="container-filters ">
          <span className="p-input-icon-left p-search ">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              placeholder="Informe uma instância ou flavor"
              onChange={(e) => onChangeSearch(e)}
              disabled={loadingMore || loading ? true : false}
            />
          </span>

          {!existOnlyOneRegion() ? (
            <div>
              <Dropdown
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.value)}
                options={cities}
                disabled={loading || loadingMore}
                optionLabel="name"
                placeholder="Selecione a região"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="datascroller-demo">
          <div className="card">
            {loading ? (
              [...Array(5).keys()].map((t) => {
                return (
                  <div key={t}>
                    <Skeleton
                      width="100%"
                      height="50px"
                      className="mb-2"
                    ></Skeleton>
                  </div>
                );
              })
            ) : (
              <DataScroller
                ref={ds}
                value={
                  filterInstances.length > 0 ? filterInstances : instancesNew
                }
                inline
                scrollHeight="500px"
                itemTemplate={itemTemplate}
                rows={Infinity}
                loader
                footer={loadingMore ? loadingButton : ""}
                emptyMessage="Nenhum resultado encontrado"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instancias;
